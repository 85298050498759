                                                                                                                                                                                                                           import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { SectionHeading } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { ReactComponent as StarIcon } from "images/star-icon.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-5.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-7.svg";

const HeaderRow = tw.div`flex justify-between items-center flex-col xl:flex-row`;
const Header = tw(SectionHeading)``;
const TabsControl = tw.div`flex flex-wrap bg-gray-200 px-2 py-2 rounded leading-none mt-12 xl:mt-0`;

const TabControl = styled.div`
  ${tw`cursor-pointer px-6 py-3 mt-2 sm:mt-0 sm:mr-2 last:mr-0 text-gray-600 font-medium rounded-sm transition duration-300 text-sm sm:text-base w-1/2 sm:w-auto text-center`}
  &:hover {
    ${tw`bg-gray-300 text-gray-700`}
  }
  ${props => props.active && tw`bg-primary-500! text-gray-100!`}
  }
`;

const TabContent = tw(motion.div)`mt-6 flex flex-wrap sm:-mr-10 md:-mr-6 lg:-mr-12`;
const CardContainer = tw.div`mt-10 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 sm:pr-10 md:pr-6 lg:pr-12`;
const Card = tw(motion.a)`bg-gray-200 rounded-b block max-w-xs mx-auto sm:max-w-none sm:mx-0`;
const CardImageContainer = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-56 xl:h-64 bg-center bg-cover relative rounded-t`}
`;
const CardRatingContainer = tw.div`leading-none absolute inline-flex bg-gray-100 bottom-0 left-0 ml-4 mb-4 rounded-full px-5 py-2 items-end`;
const CardRating = styled.div`
  ${tw`mr-1 text-sm font-bold flex items-end`}
  svg {
    ${tw`w-4 h-4 fill-current text-orange-400 mr-1`}
  }
`;

const CardHoverOverlay = styled(motion.div)`
  background-color: rgba(255, 255, 255, 0.5);
  ${tw`absolute inset-0 flex justify-center items-center`}
`;
const CardButton = tw(PrimaryButtonBase)`text-sm`;

const CardReview = tw.div`font-medium text-xs text-gray-600`;

const CardText = tw.div`p-4 text-gray-900`;
const CardTitle = tw.h5`text-lg font-semibold group-hover:text-primary-500`;
const CardContent = tw.p`mt-1 text-sm font-medium text-gray-600`;
const CardPrice = tw.p`mt-4 text-xl font-bold`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 opacity-15 transform translate-x-2/3 -translate-y-12 text-pink-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-80 w-80 opacity-15 transform -translate-x-2/3 text-primary-500`}
`;

export default ({
  heading = "Checkout the Menu",
  tabs = {
    Starters: [
      {
        imageSrc:
          "/images/menu/stuff_mama/starters/chicken/chicken_majestic.jpg",
        title: "Chicken Majestic",
        content: "Chicken Majestic",
        
        rating: "4.8",
        reviews: "32",
        url: "#"
      },
      {
        imageSrc:
          "/images/menu/stuff_mama/starters/chicken/chicken65.jpg",
        title: "Chicken 65",
        content: "Chicken 65",
        
        rating: "4.8",
        reviews: "32",
        url: "#"
      },
      {
        imageSrc:
        "/images/menu/stuff_mama/starters/chicken/chilli_chicken.jpg",
        title: "Chilli Chicken",
        content: "Chilli Chicken",
        
        rating: "4.9",
        reviews: "89",
        url: "#"
      },
      {
        imageSrc:
        "/images/menu/stuff_mama/starters/chicken/r_chicken_vepudu.jpg",
        title: "Rayalaseema Chicken Vepudu",
        content: "",
        
        rating: "4.9",
        reviews: "89",
        url: "#"
      },
      {
        imageSrc:
          "/images/menu/stuff_mama/starters/chicken/chicken_pakoda.jpg",
        title: "Chicken Pakoda",
        content: "Bone",
        
        rating: "5.0",
        reviews: "87",
        url: "#"
      },
      {
        imageSrc:
          "/images/menu/stuff_mama/starters/chicken/kaju_chicken_pakoda.jpg",
        title: "Kaju Chicken Pakoda",
        content: "Kaju Chicken Pakoda",
        
        rating: "4.6",
        reviews: "12",
        url: "#"
      },
      {
        imageSrc:
          "/images/menu/stuff_mama/starters/chicken/korean_fried wings.jpeg",
        title: "Korean Fried Wings",
        content: "Korean Fried Wings",
        
        rating: "4.2",
        reviews: "19",
        url: "#"
      },
      {
        imageSrc:
          "/images/menu/stuff_mama/starters/chicken/kurkure_chicken.jpeg",
        title: "Kurkure Chicken",
        content: "Kurkure Chicken",
        
        rating: "5.0",
        reviews: "61",
        url: "#"
      },
      {
        imageSrc:
          "/images/menu/stuff_mama/starters/egg/egg_chilly.jpg",
        title: "Egg Chilly",
        content: "Egg Chilly",
        
        rating: "4.2",
        reviews: "95",
        url: "#"
      },
      {
        imageSrc:
          "/images/menu/stuff_mama/starters/egg/egg65.jpg",
        title: "Egg 65",
        content: "Egg 65",
        
        rating: "4.2",
        reviews: "95",
        url: "#"
      },
      {
        imageSrc:
          "/images/menu/stuff_mama/starters/egg/egg_pakoda.png",
        title: "Egg Pakoda",
        content: "Egg Pakoda",
        
        rating: "3.9",
        reviews: "26",
        url: "#"
      },
      {
        imageSrc:
          "/images/menu/stuff_mama/starters/fish/tawa_fish_bone.jpg",
        title: "Tawa Fish",
        content: "Bone",
        
        rating: "4.0",
        reviews: "26",
        url: "#"
      },
      {
        imageSrc:
          "/images/menu/stuff_mama/starters/fish/tawa_fish_boneless.jpeg",
        title: "Tawa Fish Boneless",
        content: "Boneless",
        
        rating: "4.9",
        reviews: "26",
        url: "#"
      },
      {
        imageSrc:
          "/images/menu/stuff_mama/starters/fish/fish_fingers.jpg",
        title: "Fish Fingers",
        content: "Fish Fingers",
        
        rating: "3.9",
        reviews: "26",
        url: "#"
      },
      {
        imageSrc:
          "/images/menu/stuff_mama/starters/veg/boiled_ masala_corn.jpg",
        title: "Boiled Masala Corn",
        content: "Boiled Masala Corn",
        
        rating: "3.9",
        reviews: "26",
        url: "#"
      }
      ,
      {
        imageSrc:
          "/images/menu/stuff_mama/starters/veg/boiled_masala_peanuts.jpg",
        title: "Boiled Masala Peanuts",
        content: "Boiled Masala Peanuts",
        
        rating: "3.9",
        reviews: "26",
        url: "#"
      }
      ,
      {
        imageSrc:
          "/images/menu/stuff_mama/starters/veg/masala_peanuts.jpeg",
        title: "Masala Peanuts",
        content: "Masala Peanuts",
        
        rating: "3.9",
        reviews: "26",
        url: "#"
      }
      ,
      {
        imageSrc:
          "/images/menu/stuff_mama/starters/veg/masala_chana.jpg",
        title: "Masala Chana",
        content: "Masala Chana",
        
        rating: "3.9",
        reviews: "26",
        url: "#"
      }
      ,
      {
        imageSrc:
          "/images/menu/stuff_mama/starters/veg/crispy_corn.jpg",
        title: "Crispy Corn",
        content: "Crispy Corn",
        
        rating: "4.0",
        reviews: "26",
        url: "#"
      }
      ,
      {
        imageSrc:
          "/images/menu/stuff_mama/starters/veg/french_fries.png",
        title: "French Fries",
        content: "French Fries",
        rating: "4.0",
        reviews: "26",
        url: "#"
      }
      ,
      {
        imageSrc:
          "/images/menu/stuff_mama/starters/veg/mushroom_croquettes.jpg",
        title: "Mushroom Croquettes",
        content: "Mushroom Croquettes",
        rating: "3.9",
        reviews: "26",
        url: "#"
      },
      {
        imageSrc:
          "/images/menu/stuff_mama/starters/veg/mushroom_chilli.jpg",
        title: "Mushroom Chilli",
        content: "Mushroom Chilli",
        rating: "3.9",
        reviews: "26",
        url: "#"
      },
      {
        imageSrc:
          "/images/menu/stuff_mama/starters/veg/mushroom65.jpeg",
        title: "Mushroom 65",
        content: "Mushroom 65",
        rating: "3.9",
        reviews: "26",
        url: "#"
      }
      ,
      {
        imageSrc:
          "/images/menu/stuff_mama/starters/veg/onion_pakoda.jpg",
        title: "Onion Pakoda",
        content: "Onion Pakoda",
        rating: "3.9",
        reviews: "26",
        url: "#"
      }
      ,
      {
        imageSrc:
          "/images/menu/stuff_mama/starters/veg/paneer_finger_fries.jpg",
        title: "Paneer Finger Fries",
        content: "Paneer Finger Fries",
        rating: "3.9",
        reviews: "26",
        url: "#"
      }
      ,
      {
        imageSrc:
          "/images/menu/stuff_mama/starters/veg/paneer_chilli.jpg",
        title: "Paneer Chilli",
        content: "Paneer Chilli",
        rating: "3.9",
        reviews: "26",
        url: "#"
      },
      {
        imageSrc:
          "/images/menu/stuff_mama/starters/veg/paneer65.png",
        title: "Paneer 65",
        content: "Paneer 65",
        rating: "3.9",
        reviews: "26",
        url: "#"
      },
      {
        imageSrc:
          "/images/menu/stuff_mama/starters/veg/masala_nachos.jpg",
        title: "Masala Nachos",
        content: "Masala Nachos",
        rating: "3.9",
        reviews: "26",
        url: "#"
      }
    ],
    
    Accessories: [
      {
        imageSrc:
          "/images/menu/Accessories/hookahpot.jpg",
        title: "Hookah Pots",
        content: "Hookah Pots",
        
        rating: "4.8",
        reviews: "32",
        url: "#"
      },
      {
        imageSrc:
          "/images/menu/Accessories/partygames.webp",
        title: "Party Games",
        content: "Party Games",
       
        rating: "4.8",
        reviews: "32",
        url: "#"
      },
      {
        imageSrc:
        "/images/menu/Accessories/ashtray.webp",
        title: "Ash Trays",
        content: "Ash Trays",
        
        rating: "4.9",
        reviews: "89",
        url: "#"
      },
      {
        imageSrc:
        "/images/menu/Accessories/grillset.webp",
        title: "Grill Sets",
        content: "Grill Sets",
     
        rating: "4.9",
        reviews: "89",
        url: "#"
      },
      {
        imageSrc:
          "/images/menu/Accessories/lighter.jpg",
        title: "Fancy Lighters",
        content: "Fancy Lighters",
      
        rating: "5.0",
        reviews: "87",
        url: "#"
      },
      {
        imageSrc:
          "/images/menu/Accessories/bar.webp",
        title: "Bar Accessories",
        content: "Bar Accessories",
        
        rating: "4.6",
        reviews: "12",
        url: "#"
      },
      {
        imageSrc:
          "/images/menu/Accessories/glass.jpg",
        title: "Unique Glass Mug Collections",
        content: "Unique Glass Collections",
       
        rating: "4.2",
        reviews: "19",
        url: "#"
      }
      
    ],

    Drinks: [
      {
        imageSrc:
          "/images/menu/Drinks/softdrinks.jpg",
        title: "Soft Drinks",
        content: "Thumpsu-up, Coca Cola, Fanta, Sprite, Pulpy Orange etc"
        ,
       
        rating: "4.8",
        reviews: "32",
        url: "#"
      },
      {
        imageSrc:
          "/images/menu/Drinks/mixer.jpg",
        title: "Wide veriety of Mixers",
        content: "Jimmys (coctails), Absolute Mixers, TonicWater Collections, Ginger Ale, etc  ",
        
        rating: "4.8",
        reviews: "32",
        url: "#"
      },
      
      {
        imageSrc:
        "/images/menu/Drinks/energydrinks.png",
        title: "Energy Drinks",
        content: "RedBull, Raw Drinks, Monster Energy, Hurricane, etc",
        
        rating: "4.9",
        reviews: "89",
        url: "#"
      },
      {
        imageSrc:
          "/images/menu/Drinks/juices.jpg",
        title: "Flavoured Drinks & Fruits Juices",
        content: "Sepoy&Co, Tropicana, Real, etc",
        
        rating: "5.0",
        reviews: "87",
        url: "#"
      },
    ],

    
    PackedFood: [
      {
        imageSrc:
          "/images/menu/packedfood/jabsons.jpg",
        title: "Jobsons Nuts",
        content: "5+ Varieties of Peanuts, Cashews, Chana etc",
        
        rating: "4.8",
        reviews: "32",
        url: "#"
      },
      {
        imageSrc:
          "/images/menu/packedfood/cn.jpg",
        title: "Chips & Nachos",
        content: "Lays, Pringles, Doritos, Cornitos, Salsalito, etc",
       
        rating: "4.8",
        reviews: "32",
        url: "#"
      },
      {
        imageSrc:
        "/images/menu/packedfood/chocolate.jpg",
        title: "50+ Varieties of Chocolates",
        content: "Hershey's, Kitkat, Ferrero Rocher, Schmitten etc",
        
        rating: "4.9",
        reviews: "89",
        url: "#"
      }
    ],

 
  }
}) => {
  /*
   * To customize the tabs, pass in data using the `tabs` prop. It should be an object which contains the name of the tab
   * as the key and value of the key will be its content (as an array of objects).
   * To see what attributes are configurable of each object inside this array see the example above for "Starters".
   */
  const tabsKeys = Object.keys(tabs);
  const [activeTab, setActiveTab] = useState(tabsKeys[0]);

  return (
    <Container>
      <ContentWithPaddingXl>
        <HeaderRow>
          <Header>{heading}</Header>
          <TabsControl>
            {Object.keys(tabs).map((tabName, index) => (
              <TabControl key={index} active={activeTab === tabName} onClick={() => setActiveTab(tabName)}>
                {tabName}
              </TabControl>
            ))}
          </TabsControl>
        </HeaderRow>

        {tabsKeys.map((tabKey, index) => (
          <TabContent
            key={index}
            variants={{
              current: {
                opacity: 1,
                scale:1,
                display: "flex",
              },
              hidden: {
                opacity: 0,
                scale:0.8,
                display: "none",
              }
            }}
            transition={{ duration: 0.4 }}
            initial={activeTab === tabKey ? "current" : "hidden"}
            animate={activeTab === tabKey ? "current" : "hidden"}
          >
            {tabs[tabKey].map((card, index) => (
              <CardContainer key={index}>
                <Card className="group" href={card.url} initial="rest" whileHover="hover" animate="rest">
                  <CardImageContainer imageSrc={card.imageSrc}>
                    <CardRatingContainer>
                      <CardRating>
                        <StarIcon />
                        {card.rating}
                      </CardRating>
                      <CardReview>({card.reviews})</CardReview>
                    </CardRatingContainer>
                    <CardHoverOverlay
                      variants={{
                        hover: {
                          opacity: 1,
                          height: "auto"
                        },
                        rest: {
                          opacity: 0,
                          height: 0
                        }
                      }}
                      transition={{ duration: 0.3 }}
                    >
                      <CardButton>Buy Now</CardButton>
                    </CardHoverOverlay>
                  </CardImageContainer>
                  <CardText>
                    <CardTitle>{card.title}</CardTitle>
                    <CardContent>{card.content}</CardContent>
                    <CardPrice>{card.price}</CardPrice>
                  </CardText>
                </Card>
              </CardContainer>
            ))}
          </TabContent>
        ))}
      </ContentWithPaddingXl>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};

/* This function is only there for demo purposes. It populates placeholder cards */
const getRandomCards = () => {
  const cards = [
    {
      imageSrc:
        "/images/menu/stuff_mama/starters/chicken/chicken_majestic.jpg",
      title: "Chicken Majestic",
      content: "Chicken Majestic",
      
      rating: "4.8",
      reviews: "32",
      url: "#"
    },
    {
      imageSrc:
        "/images/menu/stuff_mama/starters/chicken/chicken65.jpg",
      title: "Chicken 65",
      content: "Chicken 65",
      
      rating: "4.8",
      reviews: "32",
      url: "#"
    },
    {
      imageSrc:
      "/images/menu/stuff_mama/starters/chicken/chilli_chicken.jpg",
      title: "Chilli Chicken",
      content: "Chilli Chicken",
      
      rating: "4.9",
      reviews: "89",
      url: "#"
    },
    {
      imageSrc:
      "/images/menu/stuff_mama/starters/chicken/r_chicken_vepudu.jpg",
      title: "Rayalaseema Chicken Vepudu",
      content: "",
      
      rating: "4.9",
      reviews: "89",
      url: "#"
    },
    {
      imageSrc:
        "/images/menu/stuff_mama/starters/chicken/chicken_pakoda.jpg",
      title: "Chicken Pakoda",
      content: "Bone",
      
      rating: "5.0",
      reviews: "87",
      url: "#"
    },
    {
      imageSrc:
        "/images/menu/stuff_mama/starters/chicken/kaju_chicken_pakoda.jpg",
      title: "Kaju Chicken Pakoda",
      content: "Kaju Chicken Pakoda",
      
      rating: "4.6",
      reviews: "12",
      url: "#"
    },
    {
      imageSrc:
        "/images/menu/stuff_mama/starters/chicken/korean_fried wings.jpeg",
      title: "Korean Fried Wings",
      content: "Korean Fried Wings",
      
      rating: "4.2",
      reviews: "19",
      url: "#"
    },
    {
      imageSrc:
        "/images/menu/stuff_mama/starters/chicken/kurkure_chicken.jpeg",
      title: "Kurkure Chicken",
      content: "Kurkure Chicken",
      
      rating: "5.0",
      reviews: "61",
      url: "#"
    },
    {
      imageSrc:
        "/images/menu/stuff_mama/starters/egg/egg_chilly.jpg",
      title: "Egg Chilly",
      content: "Egg Chilly",
      
      rating: "4.2",
      reviews: "95",
      url: "#"
    },
    {
      imageSrc:
        "/images/menu/stuff_mama/starters/egg/egg65.jpg",
      title: "Egg 65",
      content: "Egg 65",
      
      rating: "4.2",
      reviews: "95",
      url: "#"
    },
    {
      imageSrc:
        "/images/menu/stuff_mama/starters/egg/egg_pakoda.png",
      title: "Egg Pakoda",
      content: "Egg Pakoda",
      
      rating: "3.9",
      reviews: "26",
      url: "#"
    },
    {
      imageSrc:
        "/images/menu/stuff_mama/starters/fish/tawa_fish_bone.jpg",
      title: "Tawa Fish",
      content: "Bone",
      
      rating: "4.0",
      reviews: "26",
      url: "#"
    },
    {
      imageSrc:
        "/images/menu/stuff_mama/starters/fish/tawa_fish_boneless.jpeg",
      title: "Tawa Fish Boneless",
      content: "Boneless",
      
      rating: "4.9",
      reviews: "26",
      url: "#"
    },
    {
      imageSrc:
        "/images/menu/stuff_mama/starters/fish/fish_fingers.jpg",
      title: "Fish Fingers",
      content: "Fish Fingers",
      
      rating: "3.9",
      reviews: "26",
      url: "#"
    },
    {
      imageSrc:
        "/images/menu/stuff_mama/starters/veg/boiled_ masala_corn.jpg",
      title: "Boiled Masala Corn",
      content: "Boiled Masala Corn",
      
      rating: "3.9",
      reviews: "26",
      url: "#"
    }
    ,
    {
      imageSrc:
        "/images/menu/stuff_mama/starters/veg/boiled_masala_peanuts.jpg",
      title: "Boiled Masala Peanuts",
      content: "Boiled Masala Peanuts",
      
      rating: "3.9",
      reviews: "26",
      url: "#"
    }
    ,
    {
      imageSrc:
        "/images/menu/stuff_mama/starters/veg/masala_peanuts.jpeg",
      title: "Masala Peanuts",
      content: "Masala Peanuts",
      
      rating: "3.9",
      reviews: "26",
      url: "#"
    }
    ,
    {
      imageSrc:
        "/images/menu/stuff_mama/starters/veg/masala_chana.jpg",
      title: "Masala Chana",
      content: "Masala Chana",
      
      rating: "3.9",
      reviews: "26",
      url: "#"
    }
    ,
    {
      imageSrc:
        "/images/menu/stuff_mama/starters/veg/crispy_corn.jpg",
      title: "Crispy Corn",
      content: "Crispy Corn",
      
      rating: "4.0",
      reviews: "26",
      url: "#"
    }
    ,
    {
      imageSrc:
        "/images/menu/stuff_mama/starters/veg/french_fries.png",
      title: "French Fries",
      content: "French Fries",
      
      rating: "4.0",
      reviews: "26",
      url: "#"
    }
    ,
    {
      imageSrc:
        "/images/menu/stuff_mama/starters/veg/mushroom_croquettes.jpg",
      title: "Mushroom Croquettes",
      content: "Mushroom Croquettes",
      
      rating: "3.9",
      reviews: "26",
      url: "#"
    },
    {
      imageSrc:
        "/images/menu/stuff_mama/starters/veg/mushroom_chilli.jpg",
      title: "Mushroom Chilli",
      content: "Mushroom Chilli",
      
      rating: "3.9",
      reviews: "26",
      url: "#"
    },
    {
      imageSrc:
        "/images/menu/stuff_mama/starters/veg/mushroom65.jpeg",
      title: "Mushroom 65",
      content: "Mushroom 65",
      
      rating: "3.9",
      reviews: "26",
      url: "#"
    }
    ,
    {
      imageSrc:
        "/images/menu/stuff_mama/starters/veg/onion_pakoda.jpg",
      title: "Onion Pakoda",
      content: "Onion Pakoda",
      
      rating: "3.9",
      reviews: "26",
      url: "#"
    }
    ,
    {
      imageSrc:
        "/images/menu/stuff_mama/starters/veg/paneer_finger_fries.jpg",
      title: "Paneer Finger Fries",
      content: "Paneer Finger Fries",
      
      rating: "3.9",
      reviews: "26",
      url: "#"
    }
    ,
    {
      imageSrc:
        "/images/menu/stuff_mama/starters/veg/paneer_chilli.jpg",
      title: "Paneer Chilli",
      content: "Paneer Chilli",
      
      rating: "3.9",
      reviews: "26",
      url: "#"
    },
    {
      imageSrc:
        "/images/menu/stuff_mama/starters/veg/paneer65.png",
      title: "Paneer 65",
      content: "Paneer 65",
      
      rating: "3.9",
      reviews: "26",
      url: "#"
    },
    {
      imageSrc:
        "/images/menu/stuff_mama/starters/veg/masala_nachos.jpg",
      title: "Masala Nachos",
      content: "Masala Nachos",
      
      rating: "3.9",
      reviews: "26",
      url: "#"
    }
  ];

  // Shuffle array
  return cards.sort(() => Math.random() - 0.5);
};
