import React from "react";
import GlobalStyles from 'styles/GlobalStyles';

/*
 * This is the entry point component of this project. You can change the below exported default App component to any of
/*
 * Hero section is the top most section on the page. It contains the header as well.
 * So you dont need to import headers
 * separately
 */

   import RestaurantLandingPage from "demos/RestaurantLandingPage.js";

/* Inner Pages */
   import LoginPage from "pages/Login.js";
  import SignupPage from "pages/Signup.js";
  import FormsPage from "pages/forms.js";
  import AboutUsPage from "pages/AboutUs.js";
  import ContactUsPage from "pages/ContactUs.js";
  import BlogIndexPage from "pages/BlogIndex.js";
  import TermsOfServicePage from "pages/TermsOfService.js";
  import PrivacyPolicyPage from "pages/PrivacyPolicy.js";


import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

export default function App() {
  // If you want to disable the animation just use the disabled `prop` like below on your page's component
  // return <AnimationRevealPage disabled>xxxxxxxxxx</AnimationRevealPage>;


  return (
    <>
      <GlobalStyles />
      <Router>
        <Routes>
          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
          <Route path="/terms-of-service" element={<TermsOfServicePage />} />
          <Route path="/" element={<RestaurantLandingPage />} />
          <Route path="/forms" element={<FormsPage />} />
        </Routes>
      </Router>
    </>
  );
}

   export {LoginPage};
   export {SignupPage};
   export {FormsPage};
   export {AboutUsPage};
   export {ContactUsPage};
   export {BlogIndexPage};
   export {TermsOfServicePage};
   export {PrivacyPolicyPage};
