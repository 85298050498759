import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";

export default () => {
  React.useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://server.fillout.com/embed/v1/';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <AnimationRevealPage>
      <div 
        style={{ width: '100%', height: '500px' }}
        data-fillout-id="gvWia8i5eSus"
        data-fillout-embed-type="standard"
        data-fillout-inherit-parameters
        data-fillout-dynamic-resize
      />
    </AnimationRevealPage>
  );
};
